import React from "react";

const FAQ = ({children}) => {
  return (
    <section className="bg-white">
      <div className="max-w-4xl mx-auto">
        <div className="px-8 mt-16">
          <h1>Die wichtigsten Fragen & Antworten auf einen Blick</h1>
        </div>
        <ul className="border-t border-blue-300">
        {children}
        </ul>
      </div>
    </section>
  )
}

export default FAQ;