import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";
import Info from "../components/info";
import FeatureItem from "../components/feature-item"
import AboutMe from "../components/about-me"
import Mission from "../components/mission"
import CallToAction from "../components/call-to-action"
import FAQ from '../components/faq'
import FAQItem from "../components/faq-item"
import Codex from "../components/codex";
import HowItWorks from "../components/how-it-works"
import Features from "../components/features";

const SchoolTripPage = ({ data }) => {
  const color = "green";

  const { contentfulSchooltripPage, contentfulDieReiseExpertin, contentfulFirmenKodex, allContentfulSchooltripFaq } = data;
  const schoolTripFaq = allContentfulSchooltripFaq.edges.map(faq => faq.node).reverse();
  
  const { 
    label,
    titel,
    heroPrimaryButtonText,
    heroPrimaryButtonUrl,
    heroSecondaryButtonText,
    heroSecondaryButtonUrl,
    heroBeschreibung,
    introTitel,
    introBeschreibung,
    introList,
    introPrimaryButtonText,
    introPrimaryButtonUrl,
    introSecondaryButtonText,
    introSecondaryButtonUrl,
    leistungenTitel,
    leistungenItems,
    reiseExpertinText,
    reiseExpertinProfil,
    missionTitel,
    missionText: { missionText },
    fairnessTitel,
    fairnessText,
    fairnessLink,
    fairnessButtonText
  } = contentfulSchooltripPage;

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <div className="bg-white">
        <div className="bg-blue-200"> 
          <Intro
            title={titel}
            label={label}
            heroPrimaryButtonText={heroPrimaryButtonText}
            heroPrimaryButtonUrl={heroPrimaryButtonUrl}
            heroSecondaryText={heroSecondaryButtonText}
            heroSecondaryButtonUrl={heroSecondaryButtonUrl}
            imgSrc={require("../images/png/river@2x.png")}
            color={color}
          >
            {heroBeschreibung.heroBeschreibung}
          </Intro>
          <Info
            title={introTitel}
            desc = {introBeschreibung.introBeschreibung}
            primaryButtonText = {introPrimaryButtonText}
            primaryButtonUrl = {introPrimaryButtonUrl}
            secondaryButtonText = {introSecondaryButtonText}
            secondaryButtonUrl = {introSecondaryButtonUrl}
            img = {require("../images/png/groupwalking@2x.png")}
          >
            {introList.map(item => <FeatureItem>{item}</FeatureItem>)}
          </Info>
          <Features title={leistungenTitel}>
            {leistungenItems.map(item => <FeatureItem>{item}</FeatureItem>)}
          </Features>
          <FAQ>
            {schoolTripFaq.map(faq => <FAQItem title={faq.frage}>{faq.antwort.antwort}</FAQItem>)}
          </FAQ>
          <HowItWorks />
          <div className="bg-white">
            <div className="md:flex max-w-4xl mx-auto">
              <AboutMe
                reiseExpertinText={reiseExpertinText}
                contentfulDieReiseExpertin={contentfulDieReiseExpertin}
                reiseExpertinProfil={reiseExpertinProfil}
              />
              <Mission
                missionTitel={missionTitel}
                missionText={missionText}
              />
            </div>
          </div>
          <Codex
            contentfulFirmenKodex={contentfulFirmenKodex}
            color={color}
          />
          <CallToAction
            fairnessTitel={fairnessTitel}
            fairnessText={fairnessText}
            fairnessLink={fairnessLink}
            fairnessButtonText={fairnessButtonText}>

            </CallToAction>
        </div>
      </div>
    </Layout>
  );
};

export default SchoolTripPage;

export const pageQuery = graphql`query SchoolTripQuery {
  contentfulSchooltripPage {
    label
    titel
    heroPrimaryButtonText
    heroPrimaryButtonUrl
    heroBeschreibung {
      heroBeschreibung
    }
    introTitel
    introBeschreibung {
      introBeschreibung
    }
    introList
    introPrimaryButtonText
    introPrimaryButtonUrl
    leistungenTitel
    leistungenItems
    reiseExpertinText {
      reiseExpertinText
    }
    reiseExpertinProfil {
      file {
        url
      }
    }
    missionTitel
    missionText {
      missionText
    }
    fairnessTitel
    fairnessText {
    	fairnessText
    }
    fairnessLink
    fairnessButtonText
  },
  contentfulDieReiseExpertin {
    titel
    name
    eMail
    nummer
    link
    profilBild {
      file {
        url
        fileName
        contentType
      }
    }
  },
  contentfulFirmenKodex {
    titel
    kodexEintrag
  }
  allContentfulSchooltripFaq {
    edges{
      node {
        frage
        antwort {
          antwort
        }
      }
    }
  }
}`