import React, {useState} from "react";
import minus from '../images/minus.svg'
import plus from '../images/plus.svg'

const FAQItem = ({title, children}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li>
      <div className="flex justify-between py-4 px-8 cursor-pointer border-b border-blue-300 text-blue-500" onClick={() => setIsOpen(!isOpen)}>
        <div className="text-lg font-normal">{title}</div>
        <img src={ isOpen ? minus : plus} alt=""/>
      </div>
      {isOpen && <div className="px-8 py-4 bg-gray-300 mb-0 border-b border-blue-300 text-blue-500 font-light text-base">{children}</div>}
    </li>
  );
}

export default FAQItem;